import Gauge from './Gauge'
import { ChartCard } from '../../../utils/styles'
import useBotBalance from '../../../hooks/useBotBalance'
import useNetworkInfo from '../../../hooks/useNetworkInfo'
import { ChainStatsResponse } from '../../../types/API'

export interface BotData {
  chainInfo?: ChainStatsResponse
  chainId: number
}

export default function Bot({ chainId, chainInfo }: BotData) {
  const { data, isLoading } = useBotBalance(chainId)
  const { data: networkInfo } = useNetworkInfo(chainId)

  const minBalance: { [key: number]: number } = {
    1: 0.1,
    5: 0.000001,
    10: 0.005,
    56: 0.02,
    100: 0.1,
    137: 2.0,
    250: 0.1,
    42161: 0.0045,
    43114: 0.1,
    34443: 0.005, 
    81457: 0.005 
  }

  return (
    <ChartCard className={isLoading ? 'loading' : ''}>
      {!isLoading && data && (
        <>
          <p className="title">Bot Balance</p>
          <Gauge
            color={Number(data?.balance) < minBalance[chainId] ? '#F94872' : '#5CE6D5'}
            percent={(Number(data?.balance) * 100) / (minBalance[chainId] * 15)}
          />
          <div className="relative">
            <h1>
              {data?.balance} {networkInfo?.symbol}
            </h1>
            <p>$ {data?.balanceUsd}</p>
          </div>
          <h1>$ {chainInfo?.averageTxCost?.toFixed(2) || '-'} avg tx cost</h1>
          <p>
            {data?.balanceUsd &&
              chainInfo?.averageTxCost &&
              '(' + (Number(data.balanceUsd) / Number(chainInfo.averageTxCost)).toFixed(2) + ' remaining tx)'}
          </p>
        </>
      )}
    </ChartCard>
  )
}
