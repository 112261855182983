import { useParams } from 'react-router-dom'
import Gauge from './Gauge'
import { ChartCard } from '../../../utils/styles'
import useBotBalance from '../../../hooks/useBotBalance'
import useSmartVault from '../../../hooks/useSmartVault'
import { formatTokenAmount } from '../../../utils/math-utils'
import { compareAddresses } from '../../../utils/web3-utils'
import { RELAYER } from '../../../constants/playerAddress'
import { ChainStatsResponse } from '../../../types/API'

export interface BotData {
  chainInfo?: ChainStatsResponse
  chainId: number
}

export default function Bot({ chainId, chainInfo }: BotData) {
  const params = useParams()
  const smartVault = useSmartVault(params.id!, chainId)
  const { data: balance, isLoading: balanceLoading } = useBotBalance(chainId)

  type RelayerGaugeEntry = {
    minVal: number
    maxVal: number
    oneVal: number
  }

  type RelayerGauge = {
    [key: number]: RelayerGaugeEntry
  }

  // TODO: add a default value
  const relayerGauge: RelayerGauge = {
    1: { minVal: 0.199, maxVal: 0.796, oneVal: 0.0796 }, // MAINNET
    10: { minVal: 0.000375, maxVal: 0.0015, oneVal: 0.00015 }, // OPTIMISM
    137: { minVal: 0.015, maxVal: 0.06, oneVal: 0.006 }, // POLYGON
    100: { minVal: 0.015, maxVal: 0.06, oneVal: 0.006 }, // GNOSIS
    42161: { minVal: 0.00625, maxVal: 0.025, oneVal: 0.0025 }, // ARBITRUM
    1101: { minVal: 0.199, maxVal: 0.796, oneVal: 0.0796 }, // ZKEVM (ZKSYNC has no value provided, assuming MAINNET equivalent)
    56: { minVal: 0.0375, maxVal: 0.15, oneVal: 0.015 }, // BSC
    8453: { minVal: 0.0003375, maxVal: 0.00135, oneVal: 0.000135 }, // BASE
    43114: { minVal: 0.37, maxVal: 1.48, oneVal: 0.148 }, // AVALANCHE
    250: { minVal: 1.975, maxVal: 7.9, oneVal: 0.79 }, // FANTOM
    146: { minVal: 1.975, maxVal: 7.9, oneVal: 0.79 }, // SONIC
    1313161554: { minVal: 0.199, maxVal: 0.796, oneVal: 0.0796 }, // AURORA (assuming MAINNET equivalent)
    34443: { minVal: 0.0003375, maxVal: 0.00135, oneVal: 0.000135 }, // MODE
    81457: { minVal: 0.0003375, maxVal: 0.00135, oneVal: 0.000135 } // BLAST
  }

  const isLoading = false

  return (
    <ChartCard className={isLoading ? 'loading' : ''}>
      {!isLoading && smartVault && (
        <>
          <p className="title">Relayer Balance</p>
          {smartVault?.data?.smartVaults?.map((item) => {
            const relayerConfigs = item.relayerConfigs || []
            const lastRelayerConfig = relayerConfigs.find((item) => compareAddresses(item.relayer.id, RELAYER))
            const relayerBalance = Number(
              formatTokenAmount(lastRelayerConfig?.balance, lastRelayerConfig?.nativeToken?.decimals, {
                digits: 2,
              })
            )
            return (
              <>
                <Gauge
                  color={relayerBalance < relayerGauge[chainId].minVal ? '#F94872' : '#5CE6D5'}
                  percent={(relayerBalance * 100) / relayerGauge[chainId].maxVal}
                />
                <div className="relative">
                  <h1>
                    {relayerBalance} {lastRelayerConfig?.nativeToken?.symbol}
                  </h1>
                  <p>Relayer deposit</p>
                </div>
                {!balanceLoading && (
                  <div>
                    <h1>{((relayerBalance * Number(balance?.price)) / (chainInfo?.averageTxCost || 1)).toFixed(2)}</h1>
                    <p>Relayer transactions</p>
                  </div>
                )}
              </>
            )
          })}
        </>
      )}
    </ChartCard>
  )
}
